<template>
  <div v-if="form">
    <v-container :fill-height="form.organization_id === null" fluid class="pt-16">
      <v-col class="absolute-center" v-if="!form.organization_id">
        <p class="font-medium text-center f-32">
          Let's extend your presence on MICELOC!
        </p>

        <p class="text-center f-18">
          Please Select an organization to get started with the listing process
        </p>
        <v-row class="text-center">
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="6">
            <organization-selector @org-selected="orgSelected" label="Select Organization"></organization-selector>
          </v-col>
          <v-col cols="12" md="3"></v-col>
        </v-row>
      </v-col>
      <v-row align="center" justify="center" class="text-center" v-if="form.organization_id">
        <v-col cols="12" md="3"></v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="12">
              <h4>Let's extend your presence on MICELOC!</h4>
              <h5 class="mt-6">What type of venue do you want to add?</h5>
              <div>
                Select a type of venue you want to add to get started. You will be guided in each step. You can save
                every step and come back to complete the required information
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-card
                class="mx-auto my-5"
                link
                max-width="374"
                style="background-color: #f9f9f9"
                @click="loadComponent('hotel')"
              >
                <div style="height: 200px; display: flex">
                  <v-img :src="hotelBgImage" height="170px" class="ma-auto"></v-img>
                </div>
                <v-card-text class="px-0" style="background-color: white">
                  <v-row class="mx-0">
                    <v-col cols="12" md="6" class="text-left text-uppercase text--primary">
                      Hotel
                    </v-col>
                    <v-col cols="12" md="6" class="text-right">
                      <span class="text-uppercase text--primary font-weight-bold">€ 9,99</span
                      ><span class="muted-text"> / month</span>
                    </v-col>
                  </v-row>

                  <v-row class="mx-0 mt-1">
                    <v-col cols="12" md="12" class="pt-2 text-left">
                      <div class="pb-2 text--primary">8 Steps</div>
                      <div class="text--secondary" style="font-size: 13px">
                        <span>Basic Information</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Hotel Information</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Room Information</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Room Configuration</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Gallery</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                class="mx-auto my-5"
                max-width="374"
                link
                style="background-color: #f9f9f9"
                @click="loadComponent('location')"
              >
                <div :style="{ background: `url(${locationBgImage}) no-repeat` }" class="bg-image-venue"></div>

                <v-card-text class="px-0" style="background-color: white">
                  <v-row class="mx-0">
                    <v-col cols="12" md="6" class="text-left text-uppercase text--primary">
                      Venue
                    </v-col>
                    <v-col cols="12" md="6" class="text-right">
                      <span class="text-uppercase text--primary font-weight-bold">€ 9,99</span
                      ><span class="muted-text"> / month</span>
                    </v-col>
                  </v-row>

                  <v-row class="mx-0 mt-1">
                    <v-col cols="12" md="12" class="pt-2 text-left">
                      <div class="pb-2 text--primary">8 Steps</div>
                      <div class="text--secondary" style="font-size: 13px">
                        <span>Basic Information</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Overview</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Amenities</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Room Configuration</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Gallery</span>
                        <v-icon class="mx-0">mdi-circle-small</v-icon>
                        <span>Safety Information</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-10">
            <v-col cols="12" md="12" class="py-0 my-0">
              <v-btn class="py-5 text-uppercase text--primary" style="width: 100%; background-color: #d0d0d0"
                >FOR CUSTOM PRICING CONTACT US</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "ChooseCategory",
  props: ["form"],
  data: () => ({
    hotelBgImage: require("../assets/images/hotel.svg"),
    locationBgImage: require("../assets/images/venue.svg")
  }),
  computed: {
    ...mapGetters(["getRules", "getOrganizations"])
  },
  mounted() {
    this.loadOrganizations();
  },
  methods: {
    moment,
    orgSelected(orgId) {
      this.form.organization_id = orgId;
    },
    loadComponent(type = "hotel") {
      this.setActiveComponent(type);
      this.$emit("loadComponent", type);
    },
    ...mapMutations(["setActiveComponent", "setVendorOrganization"]),
    ...mapActions(["loadOrganizations"])
  }
};
</script>
<style scoped>
.bg-image-venue {
  height: 200px;
  background-size: 100% 100% !important;
}

.absolute-center {
  margin: 0 auto;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
</style>
